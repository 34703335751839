<style lang="less" scoped>
.detailBox {
    min-height: 500px;
    margin:0 auto 20px;
    background: rgba(5, 133, 197, 0.7);
    border-radius: 10px;
    box-shadow: 0 0 10px #000000;
    width: 65vw;
}
.detailTitle {
    margin:0;
    padding: 30px;
    text-align: center;
}
.imgWrap {
    padding: 0 30px;
    // border: solid #888 1px;
    >div {
        width: 32%;
        height: 19vw;
        // background: magenta;
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        margin: 10px 0;
        float: left;
        margin-left: 1%;
        margin-bottom:3.9%;
        cursor: pointer;
        position: relative;
        >p {
            position: absolute;
            left: 0;
            top: 103%;
        }
    }
}
.detainMain {
    width: 100%;
    color: #fff !important;
    padding: 0 40px;
}
.width1000 {
    max-width: 65vw;
    margin: auto;
}
.newsImg {
    width: 45%;
    height: 35vw;
    float: left;
    overflow:hidden;
    margin-left: 3vw;
    margin-top: 3vw;
    >img {
        height: 100%;
        width: auto;
    }
}
.whiteColor2 {
    color: #fff !important;
    p,li,a,span {
        color: #fff !important;
    }
}
p,li,a,span {
    color: #fff !important;
}
</style>

<template>
    <div style="padding-top: 100px;">
        <div class="detailBox newsBg whiteColor2">
            <h1 class="detailTitle">{{title}}</h1>

            <el-carousel :height="height + 'vw'" @change="changeHeight" :autoplay="false"> 
                <el-carousel-item v-for="(page,k) in files" :key="k">
                    <div class="imgWrap clearBoth">
                        <div v-for="(img,i) in page" :key="i" 
                            @click="toDetail(i)"
                            :style="{background:`url(${img.img[0]})`}">
                            <p>{{img.title}}</p> 
                        </div>
                        
                    </div>
                </el-carousel-item>
            </el-carousel>

            
            
            <!-- 移动 -->
            <!-- <div class="mShow">
                <div class="clearBoth mShow">
                    <div class="newsImg ju al" v-for="(img,i) in item.big_image" :key="i" @click="preview(i)">
                        <img :src="img" alt="">
                    </div>
                </div>
                <div class="detainMain">
                    <div v-html="item.content" class="whiteColor2"></div>
                </div>
            </div>
             -->


        </div>
        <br>
        <div class="width1000">
            <div class="sb">
               <div class="blueBtn" @click="back">
                    &lt; Back
                </div> 
            </div>
            
        </div>
        <div class="width1000">
            <br>
            <br>
            <br><br>
            <br><br>
            <br>
            <myFooter></myFooter>
        </div>


        
        
    </div>
</template>

<script>
import box1 from "./box1.js"
import box2 from "./box2.js"
import box3 from "./box3.js"
export default {
    data () {
        return {
            uuid: "",
            files: [],
            height: 10,
            index: 0,
            title: "",
        }
    },
    created () {
        this.uuid = this.$route.query.index
        this.createImgs()
        if (this.uuid == "1") {
            this.title = 'Company Activites'
        }
        this.changeHeight(0)
    },
    methods:{
        toDetail (i) {
            let item = this.files[this.index][i]
            this.$router.push({
                name: "imgDetail",
                params: {
                    item
                }
            })
        },
        changeHeight (e) {
            document.documentElement.scrollTop = 0
            this.index = e
            let num = Math.ceil(this.files[e].length / 3)
            this.height = num * 25
            if (this.height == 0) {
                this.height = 5
            }
        },
        createImgs () {
            if (this.uuid == 1) {
                this.files = box1
            } else if (this.uuid == 2) {
                this.files = box2
            } else if (this.uuid == 3) {
                this.files = box3
            }
        },
        back () {
            this.$router.back()
        },
    }
}
</script>

