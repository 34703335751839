import b3_1_1 from "@/assets/img/culture/box3/1/1.jpg"
import b3_1_2 from "@/assets/img/culture/box3/1/2.jpg"

import b3_2_1 from "@/assets/img/culture/box3/2/1.jpg"
import b3_2_2 from "@/assets/img/culture/box3/2/2.jpg"

import b3_3_1 from "@/assets/img/culture/box3/3/1.jpg"
import b3_3_2 from "@/assets/img/culture/box3/3/2.jpg"

import b3_4_1 from "@/assets/img/culture/box3/4/1.jpg"
import b3_4_2 from "@/assets/img/culture/box3/4/2.jpg"
import b3_4_3 from "@/assets/img/culture/box3/4/3.jpg"
import b3_4_4 from "@/assets/img/culture/box3/4/4.jpg"
import b3_4_5 from "@/assets/img/culture/box3/4/5.jpg"
import b3_4_6 from "@/assets/img/culture/box3/4/6.jpg"






export default [
    [
        {
            title: "Little ones poverty relief ltd",
            img: [b3_1_1,b3_1_2]
        },
        {
            title: "World Vision – Child sponsorship",
            img: [b3_2_1,b3_2_2]
        },
        {
            title: "Caring Company",
            img: [b3_3_1,b3_3_2]
        },
        {
            title: "4-DSD open day",
            img: [b3_4_1, b3_4_2, b3_4_3, b3_4_4, b3_4_5, b3_4_6 ]
        }
    ]
]