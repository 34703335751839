
import b2_1_4 from "@/assets/img/culture/box2/1/4.jpg"
import b2_1_5 from "@/assets/img/culture/box2/1/5.jpg"
import b2_1_6 from "@/assets/img/culture/box2/1/6.jpg"
import b2_1_7 from "@/assets/img/culture/box2/1/7.jpg"
import b2_1_8 from "@/assets/img/culture/box2/1/8.jpg"
import b2_1_9 from "@/assets/img/culture/box2/1/9.jpg"
import b2_1_10 from "@/assets/img/culture/box2/1/10.jpg"
import b2_1_11 from "@/assets/img/culture/box2/1/11.jpg"
import b2_1_12 from "@/assets/img/culture/box2/1/12.jpg"
import b2_1_13 from "@/assets/img/culture/box2/1/13.jpg"
import b2_1_14 from "@/assets/img/culture/box2/1/14.jpg"
import b2_1_15 from "@/assets/img/culture/box2/1/15.jpg"
import b2_1_16 from "@/assets/img/culture/box2/1/16.jpg"
import b2_1_17 from "@/assets/img/culture/box2/1/17.jpg"
import b2_1_18 from "@/assets/img/culture/box2/1/18.jpg"
import b2_1_19 from "@/assets/img/culture/box2/1/19.jpg"
import b2_1_20 from "@/assets/img/culture/box2/1/20.jpg"
import b2_1_21 from "@/assets/img/culture/box2/1/21.jpg"
import b2_1_22 from "@/assets/img/culture/box2/1/22.jpg"
import b2_1_23 from "@/assets/img/culture/box2/1/23.jpg"
import b2_1_24 from "@/assets/img/culture/box2/1/24.jpg"
import b2_1_25 from "@/assets/img/culture/box2/1/25.jpg"
import b2_1_26 from "@/assets/img/culture/box2/1/26.jpg"
import b2_1_27 from "@/assets/img/culture/box2/1/27.jpg"
import b2_1_28 from "@/assets/img/culture/box2/1/28.jpg"
import b2_1_29 from "@/assets/img/culture/box2/1/29.jpg"
import b2_1_30 from "@/assets/img/culture/box2/1/30.jpg"
import b2_1_31 from "@/assets/img/culture/box2/1/31.jpg"

import b2_2_1 from "@/assets/img/culture/box2/2/1.jpg"
import b2_2_2 from "@/assets/img/culture/box2/2/2.jpg"
import b2_2_3 from "@/assets/img/culture/box2/2/3.jpg"

import b2_3_1 from "@/assets/img/culture/box2/3/1.jpg"
import b2_3_2 from "@/assets/img/culture/box2/3/2.jpg"
import b2_3_3 from "@/assets/img/culture/box2/3/3.jpg"
import b2_3_4 from "@/assets/img/culture/box2/3/4.png"

import b2_4_1 from "@/assets/img/culture/box2/4/4.png"

import b2_5_1 from "@/assets/img/culture/box2/5/1.jpg"
import b2_5_2 from "@/assets/img/culture/box2/5/2.jpg"
import b2_5_3 from "@/assets/img/culture/box2/5/3.jpg"
import b2_5_4 from "@/assets/img/culture/box2/5/4.jpg"
import b2_5_5 from "@/assets/img/culture/box2/5/5.png"

import b2_6_1 from "@/assets/img/culture/box2/6/1.jpg"
import b2_6_2 from "@/assets/img/culture/box2/6/2.jpg"
import b2_6_3 from "@/assets/img/culture/box2/6/3.jpg"
import b2_6_4 from "@/assets/img/culture/box2/6/4.jpg"
import b2_6_5 from "@/assets/img/culture/box2/6/5.jpg"
import b2_6_6 from "@/assets/img/culture/box2/6/6.jpg"
import b2_6_7 from "@/assets/img/culture/box2/6/7.jpg"
import b2_6_8 from "@/assets/img/culture/box2/6/8.png"

import b2_7_1 from "@/assets/img/culture/box2/7/1.jpg"
import b2_7_2 from "@/assets/img/culture/box2/7/2.jpg"
import b2_7_3 from "@/assets/img/culture/box2/7/3.jpg"

import b2_8_1 from "@/assets/img/culture/box2/8/1.jpg"
import b2_8_2 from "@/assets/img/culture/box2/8/2.jpg"

import b2_9_1 from "@/assets/img/culture/box2/9/1.jpg"
import b2_9_2 from "@/assets/img/culture/box2/9/2.jpg"
import b2_9_3 from "@/assets/img/culture/box2/9/3.jpg"

import b2_10_1 from "@/assets/img/culture/box2/10/1.jpg"
import b2_10_2 from "@/assets/img/culture/box2/10/2.jpg"
import b2_10_3 from "@/assets/img/culture/box2/10/3.jpg"

import b2_11_1 from "@/assets/img/culture/box2/11/1.png"

import b2_12_1 from "@/assets/img/culture/box2/12/1.png"
import b2_12_2 from "@/assets/img/culture/box2/12/2.png"





export default [
    [
        {
            title: "2016 CHKSTT conference",
            img: [ b2_1_4, b2_1_5, b2_1_6, b2_1_7, b2_1_8, b2_1_9, b2_1_10, b2_1_11, b2_1_12, b2_1_13, b2_1_14, b2_1_15, b2_1_16, b2_1_17, b2_1_18,
                b2_1_19, b2_1_20, b2_1_21,  b2_1_22,  b2_1_23,  b2_1_24,  b2_1_25,  b2_1_26,  b2_1_27,  b2_1_28,  b2_1_29,  b2_1_30,  b2_1_31,  ]
        },
        {
            title: "Technical exchange with WSD",
            img: [b2_2_1, b2_2_2, b2_2_3]
        },
        {
            title: "Technical exchange with JSTT",
            img: [b2_3_1, b2_3_2, b2_3_3,b2_3_4],
            text: [
                'Trenchless International',
                "China Hong Kong Society for Trenchless Technology (CHKSTT) and Japan Society for Trenchless Technology (JSTT), together with the Department of Land Surveying and Geo-Informatics of the Hong Kong Polytechnic University (HKPolyU) as a co-organizer and the Young Members Committee of the Hong Kong Institution of Engineers (HKIE-YMC) as a supporting organization, had jointly organized a Technical Forum on Trenchless Pipe Rehabilitation / Installation Techniques in Japan on 17 April 2015 (Friday) at HKPolyU.",
                "This is the first for CHKSTT and JSTT to collaborate on promoting the importance of trenchless technology to meet the needs of the ever-increasing global challenges of the underground infrastructure. The Japanese delegates include Mr. Sean Lim of Sekisui Asia Pipe Solutions, Ms. Kyoko Kondo of ISTT / JSTT (also on behalf of Mr. Fumihiko Matsumoto of Alpha Civil Engineering Inc.), Dr. Makoto Kaneuji of Toa Grout Co. Ltd, Mr. Hiroshi Ito of Ashimori Industry Co. Ltd. and Mr. Matsui, the past Chairman of JSTT.",
                "The response was overwhelming and we had received over 100 applications. It became clear that there is a strong demand for training on pipe rehabilitation and installation techniques. Participants include engineers from various government departments, mainly Drainage Services Department and Water Supplies Department, contractors and consultants.",
                "Before the Technical Forum, all participants were invited by Prof. Wallace Lai to visit the new underground utility surveying laboratory of The Department of Land Surveying and Geo-informatics of the Hong Kong Polytechnic University. The laboratory contains a raised floor which embeds various types of underground utilities. With accurate records of utility alignment, orientations, flow directions, pipe materials, pipe sizes, it is made available for method validation of utility survey instrument, such as ground penetrating radar, pipe cable locator, robotic CCTV, etc, as well as scenario simulation like water leakage and subsurface void.",
                "The Technical forum was kicked off by a welcoming address from Mr. K.K. Yan, the Chairman of CHKSTT, and a presentation on the updates on trenchless technology in Hong Kong by Mr. Wing Chan, the Vice Chairman of CHKSTT. Mr. Sean Lim, Ms. Kyoko Kondo, Dr. Makoto Kaneuji, Mr. Hiroshi Ito then presented materials on pipe renewal technologies, pipe jacking methods, light-curing methods and pipe rehabilitation respectively. Mr. K.K. Yan and Mr. Matsui concluded the Technical Forum on a happy note and by thanking everyone for their participation.",
                "CHKSTT look forward to more collaboration opportunities with ISTT affiliated societies and other engineering partners in the future to come."
            ]
        },
        {
            title: "Technical exchange with CACP from China",
            img: [b2_4_1],
            text: [
                "Technical exchange with Underground Pipeline Committee of CACP from China",
                "Agenda:",
                `
                    <ul>
                        <li style="margin:10px 30px;">Site visit for CCTV application in the morning</li>
                    </ul>
                `,
                "About 20 Chinese representatives come to learn the application and operation of CCTV in Hong Kong. Waterland has sent out a team to deliver thorough demonstration and explanation for the detail of setup, actual working procedure and the instant image of the CCTV.",
                `
                    <ul>
                        <li style="margin:10px 30px;">Meeting with DSD for understanding the operation of Hong Kong hydrological data center</li>
                    </ul>
                `,
                "Through the technical exchange session in the topic of Hong Kong hydrological data center operation, 5 Chinese Representatives have gained in-depth understanding of the operational principles in Drainage Services Department of HKSAR. The session is interactive and each party was able to exchange valuable information for the future development.",
                "Date: 21 Jun 2013",
                "Co organized with",
                `<ul>
                    <li style="margin:10px 30px;">China Hong Kong Society for Trenchless Technology</li>
                    <li style="margin:10px 30px;">Beijing tsinghua tongheng urban planning & design institute dept of environmental technology</li>
                    <li style="margin:10px 30px;">Baoding jindi Zhidian Pipeline Inspection Technology Development Co., Ltd.</li>
                </ul>`

            ]
        },
        {
            title: "Technical exchange with BSOME",
            img: [b2_5_1, b2_5_2, b2_5_3, b2_5_4, b2_5_5]
        },
        {
            title: "2014 CHKSTT conference",
            img: [b2_6_1, b2_6_2, b2_6_3, b2_6_4, b2_6_5, b2_6_6, b2_6_7, b2_6_8]
        },
        {
            title: "Housing Society Seminar",
            img: [b2_7_1, b2_7_2, b2_7_3],
            text: [
                `
                <i style='padding-right: 10px;'>Housing Society Seminar </i>
                Seminar of Non-destructive Utilities Surveys, Trenchless Rehabilitation for Infrastructures and
                The Introduction and Application of Water Pressure Management in Building Trade
                `,
                "Due to the increasing awareness of the building structure integrity and the needs for non-destructive utility surveying techniques, Mr KK Yan has provided an introduction for a vast range of non-destructive utility survey technologies available both in building application and underground. As seeing the rising requirement for building structure diagnosis, the presentation has specifically included topics like water leakage detection in building application, pipe wall thickness assessment, etc. We believe by exchanging information of technologies in the market, the property management services will be enhanced with knowledge of appropriate technologies application.",
                `<div>
                    <p>Speaker: Mr KK Yan</p>
                    <p>Date: 27 June 2013 (Thu) </p>
                    <p>Time: 2:00pm to 5:00pm</p>
                    <p>No. of Participants: over 50</p>
                </div>`
            ]
        },
        {
            title: "Building Department Technical Exchange",
            img: [b2_8_1, b2_8_2],
            text: [
                'The technical exchange session mainly focuses on the surveying techniques in building diagnosis application. We have introduced technologies to detect water leakage in the building through water path identification, surveying techniques to diagnose possible default in the concrete and Trenchless technologies to obtain pipe wall condition.The discussion was enriched by enthusiastic responses and both parties were able to gain much insight in building application.',
                `
                    <div>
                        <p>Speaker: Mr KK Yan, Mr Jacky So</p>
                        <p>Date: 9th May 2013</p>
                        <p>Venue: Building Department</p>
                    </div>
                `
            ]
        },
        {
            title: "Housing Authority Presentation Summary",
            img: [ b2_9_1, b2_9_2, b2_9_3 ],
            text: [
                'Housing Authority Presentation Summary',
                "Seminar for Underground Utility Surveying Techniques, Trenchless Pipe Rehabilitation Works & Water Pressure Management in High Rise Building",
                `On 7th May 2013, Mr KK Yan was invited by Hong Kong Housing Authority to share experience and expertise in trenchless technology industry. The presentation was well received and most of the attendees showed great interests in learning more about various technologies available in the area of utility survey, pipe rehabilitation and water pressure management both in building and underground application.
                Presentation has covered technologies through a vast range of case studies for in-depth elaboration. Topics included subsoil condition, concrete internal integrity survey, various combinations of water leakage control technologies in building trade and underground application, data acquisition system to monitor flow and pressure data, material and techniques choices in pipe and manhole rehabilitation and water pressure management through engineering based valve.
                By sharing the advanced techniques available in the market, we aim to provide useful information and hand-on experiences to assist the users for better property service management.
                `,
                `<div>
                    <p>Speaker: Mr KK Yan Date: 7th May 2013</p>
                    <p>Venue: Lecture Theatre, 4/F Lung Cheung Office Block,138 Lung Cheung Road,</p>
                    <p>Wong Tai Sin, Kowloon No. of Participants:</p>
                    <p>60-80 persons</p>
                </div>`
            ]
        },
    ],
    [
        {
            title: "Public Seminar in The Hong Kong Polytechnic University",
            img: [b2_10_1, b2_10_2, b2_10_3],
            text: [
                'Public Seminar in The Hong Kong Polytechnic University',
                "Topic: Underground Utility Surveying Techniques, Trenchless Pipe Rehabilitation Works & Water Pressure Management in High Rise Building",
                "Due to the rising concerns over the aging problems in underground infrastructural facilities, as part of the pioneers in trenchless technologies industries, Mr KK Yan was invited to deliver in-depth sharing of advanced technologies in dealing with issues about utility survey and underground utilities management.",
                "The seminar was divided into two sections. Section one introduce the updated technology of Engineering Survey for utilities used in Hong Kong including Utility Mapping, Topographical Surveys, Geophysical & Geotechnical Engineering Surveys, Water Leakage Detection, Pipeline Condition Assessment Surveys and Trenchless Pipe Rehabilitation Techniques.",
                "Section two facilitates property management companies or owners to understand the concept of pressure management, since many of them face malfunction and disturbance of the systems caused by surge, water hammer, noise and leak problem. A vast variety of engineering based valves were introduced to provide possible solution for users to deal with such issues",
                `<div>
                    <p>Speaker: Mr KK Yan</p>
                    <p>Date: 23rd February 2013</p>
                    <p>Venue: the Hong Kong Polytechnic University</p>
                    <p>No. of Participants: 100</p>
                    <p>Seminar co-organized with The Chartered Instituted of Plumbing and</p>
                    <p>Heating Engineering- Hong Kong Branch</p>
                    <p>Heating Engineering- Hong Kong Branch</p>
                    <p>The Hong Kong Polytechnic University</p>
                </div>`
            ]
        },
        {
            title: 'International Conference and Exhibition for Trenchless Technologies and Water & Wastewater Industries 2011',
            img: [b2_11_1],
            text: [
                `International Conference and Exhibition for Trenchless Technologies and
                Water & Wastewater Industries 2011International Conference and Exhibition for Trenchless Technologies and
                Water & Wastewater Industries 2011
                `,
                `Focus Hong Kong has one of the largest water mains rehabilitation programmes in the world. Much of the work is done in an urban busy environment. Installation and rehabilitating pipes and cables beneath the sheets by trenchless means require novel techniques and much planning and liaison with the local authorities. Delegates to this conference will have an opportunity to learn about rehabilitation works, the reduction of non-revenue water in water mains and the stopping infiltration and exfiltration in sewers and drains from international experts in the field.`,
                `<div>
                    <p>Conference Chairman: Mr. K.K. Yan, CHKSTT</p>
                    <p>Function Period: 28-29 September 2011</p>
                    <p>Conference Venue: The Cityview Hotel, 23 Waterloo Road, Kowloon, HK</p>
                </div>
                `,
            ]
        },
        {
            title: "Trenchless Technologies in Asia Pacific – Hong Kong 2009",
            img: [b2_12_1, b2_12_2],
            text: [
                "Trenchless Technologies in Asia Pacific – Hong Kong 2009: Achieving Environmental Sustainability",
                `<div>
                    <p>Conference</p>
                    <p>Trenchless Technologies in Asia Pacific – Hong Kong 2009 will provide an international forum for discussion on the latest techniques and developments in trenchless technology. In addition to a number of technical papers, the programme is highlighted case studies of major projects already undertaken in the Pacific Region as well as the rest of the world.
                    </p>
                </div>`,
                `<div>
                    <p>Conference Chairman: Mr. Jon Boon, CHKSTT</p>
                    <p>Secretary General: Professor Ming Lu, HK Polytechnic University</p>
                    <p>Function Period: 18-19 November 2009</p>
                    <p>Conference Venue: Hong Kong Science Park, Phase 2, Shatin</p>
                </div>`,
                `<a target="_blank" href='http://167.179.100.249:8188/static/IMG/f72f4ff629674ef7a4fe93702113ebd2.pdf' style="color: #000;">Download the Bulletin</a>`
            ]
        }
    ]
]

