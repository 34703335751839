// 第一个
import b1_1_1 from "@/assets/img/culture/box1/1/1.jpg"
import b1_1_2 from "@/assets/img/culture/box1/1/2.jpg"
import b1_1_3 from "@/assets/img/culture/box1/1/3.jpg"
import b1_1_4 from "@/assets/img/culture/box1/1/4.jpg"
import b1_1_5 from "@/assets/img/culture/box1/1/5.jpg"
import b1_1_6 from "@/assets/img/culture/box1/1/6.jpg"
import b1_1_7 from "@/assets/img/culture/box1/1/7.jpg"
import b1_1_8 from "@/assets/img/culture/box1/1/8.jpg"
import b1_1_9 from "@/assets/img/culture/box1/1/9.jpg"
import b1_1_10 from "@/assets/img/culture/box1/1/10.jpg"

import b1_2_1 from "@/assets/img/culture/box1/2/1.png"
import b1_2_2 from "@/assets/img/culture/box1/2/2.png"

import b1_3_1 from "@/assets/img/culture/box1/3/1.png"
import b1_3_2 from "@/assets/img/culture/box1/3/2.jpg"
import b1_3_3 from "@/assets/img/culture/box1/3/3.jpg"
import b1_3_4 from "@/assets/img/culture/box1/3/4.jpg"
import b1_3_5 from "@/assets/img/culture/box1/3/5.jpg"
import b1_3_6 from "@/assets/img/culture/box1/3/6.jpg"
import b1_3_7 from "@/assets/img/culture/box1/3/7.jpg"

import b1_4_1 from "@/assets/img/culture/box1/4/1.jpg"
import b1_4_2 from "@/assets/img/culture/box1/4/2.jpg"
import b1_4_3 from "@/assets/img/culture/box1/4/3.jpg"
import b1_4_4 from "@/assets/img/culture/box1/4/4.jpg"
import b1_4_5 from "@/assets/img/culture/box1/4/5.png"

import b1_5_1 from "@/assets/img/culture/box1/5/1.jpg"
import b1_5_2 from "@/assets/img/culture/box1/5/2.jpg"
import b1_5_3 from "@/assets/img/culture/box1/5/3.jpg"
import b1_5_4 from "@/assets/img/culture/box1/5/4.jpg"
import b1_5_5 from "@/assets/img/culture/box1/5/5.png"

export default [
    [
        { 
            title: "Birthday Party", 
            text: [],
            img: [b1_1_1, b1_1_2,b1_1_3,b1_1_4,b1_1_5,b1_1_6,b1_1_7,b1_1_8,b1_1_9,b1_1_10],
        },

        { 
            title: "10th Annviversary",
            img: [b1_2_1,b1_2_2],
            text: [
                "It has been a decade since the Waterland Detection Engineering Limited has been established. The company has gone through significant change in its scale as well as type of services. The beginning of the company comes with the work of the underground utility survey. With the gradual maturation of the industry, services such as the hydrological survey, waste water leak detection, geophysical survey and the pipe condition assessment have been put forward as the core of the company. ",
                "When there are introduction of several type of services, the scale of the company is, at the same time, increasing. The widening of services has brought the company with professional experiences and technological know-how.",
                "With regards to the history of the company as well as its current marketing status, it has symbolized hardship and creativity which boost up the development of the company. With these, the Waterland Detection Engineering Limited can develop even faster in the coming years"
            ]
        },
        {
            title: "Dumpling feast 2011",
            img: [b1_3_1,b1_3_2,b1_3_3,b1_3_4,b1_3_5,b1_3_6,b1_3_7],
        },
        {
            title: "Dumpling feast 2009",
            img: [b1_4_1, b1_4_2, b1_4_3, b1_4_4, b1_4_5 ],
        },
        {
            title: "Easter Holiday BBQ",
            img: [b1_5_1, b1_5_2, b1_5_3, b1_5_4, b1_5_5]
        },

    ],
    
]